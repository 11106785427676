<template>
  <div class="container-fluid container">
    <Breadcrumb />
    <CounselingSchoolTypeDialog
      :visible="true"
      @submit="(counselingSchoolsType) => updateCounselingSchoolType(counselingSchoolsType)"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import CounselingSchoolTypeDialog from "@/components/counselingSchoolsType/CounselingSchoolTypeDialog";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "Ivy-Way Academy"
    };
  },
  mixins: [roleMixin],
  components: {
    Breadcrumb,
    CounselingSchoolTypeDialog
  },
  methods: {
    async updateCounselingSchoolType(counselingSchoolsType) {
      await this.$store.dispatch("profile/updateStudentProfile", {
        counseling_schools_type: counselingSchoolsType
      });

      this.$router.push({ name: "counseling" });
    }
  }
};
</script>

<style scoped>
</style>
